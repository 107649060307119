// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-page-career-js": () => import("./../../../src/templates/page-career.js" /* webpackChunkName: "component---src-templates-page-career-js" */),
  "component---src-templates-page-connectivity-js": () => import("./../../../src/templates/page-connectivity.js" /* webpackChunkName: "component---src-templates-page-connectivity-js" */),
  "component---src-templates-page-contact-js": () => import("./../../../src/templates/page-contact.js" /* webpackChunkName: "component---src-templates-page-contact-js" */),
  "component---src-templates-page-events-js": () => import("./../../../src/templates/page-events.js" /* webpackChunkName: "component---src-templates-page-events-js" */),
  "component---src-templates-page-infinity-common-subpage-js": () => import("./../../../src/templates/page-infinity-common-subpage.js" /* webpackChunkName: "component---src-templates-page-infinity-common-subpage-js" */),
  "component---src-templates-page-infinity-oem-js": () => import("./../../../src/templates/page-infinity-oem.js" /* webpackChunkName: "component---src-templates-page-infinity-oem-js" */),
  "component---src-templates-page-infinity-overview-js": () => import("./../../../src/templates/page-infinity-overview.js" /* webpackChunkName: "component---src-templates-page-infinity-overview-js" */),
  "component---src-templates-page-infinity-product-comparison-js": () => import("./../../../src/templates/page-infinity-product-comparison.js" /* webpackChunkName: "component---src-templates-page-infinity-product-comparison-js" */),
  "component---src-templates-page-infinity-tech-js": () => import("./../../../src/templates/page-infinity-tech.js" /* webpackChunkName: "component---src-templates-page-infinity-tech-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-page-news-js": () => import("./../../../src/templates/page-news.js" /* webpackChunkName: "component---src-templates-page-news-js" */),
  "component---src-templates-page-oems-js": () => import("./../../../src/templates/page-oems.js" /* webpackChunkName: "component---src-templates-page-oems-js" */),
  "component---src-templates-page-quant-js": () => import("./../../../src/templates/page-quant.js" /* webpackChunkName: "component---src-templates-page-quant-js" */),
  "component---src-templates-page-resources-js": () => import("./../../../src/templates/page-resources.js" /* webpackChunkName: "component---src-templates-page-resources-js" */),
  "component---src-templates-page-simple-contact-js": () => import("./../../../src/templates/page-simple-contact.js" /* webpackChunkName: "component---src-templates-page-simple-contact-js" */),
  "component---src-templates-page-solutions-js": () => import("./../../../src/templates/page-solutions.js" /* webpackChunkName: "component---src-templates-page-solutions-js" */),
  "component---src-templates-page-team-js": () => import("./../../../src/templates/page-team.js" /* webpackChunkName: "component---src-templates-page-team-js" */),
  "component---src-templates-page-text-js": () => import("./../../../src/templates/page-text.js" /* webpackChunkName: "component---src-templates-page-text-js" */),
  "component---src-templates-page-vision-js": () => import("./../../../src/templates/page-vision.js" /* webpackChunkName: "component---src-templates-page-vision-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

